import tw from 'tailwind.macro'
import styled from 'styled-components'
import { screens } from '../../tailwind'

const Inner = styled.div`
  ${tw`w-full m-auto text-left`};

  @media (min-width: ${screens.xl}) {
    max-width: ${screens.xl};
  }
`

export default Inner
