import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import tw from 'tailwind.macro'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import { colors, screens } from '../../tailwind'
import Inner from '../elements/Inner'

const MenuContent = styled.div`
  ${tw`absolute w-full px-6 py-6 md:px-12`};
  z-index: 100;
  top: 0;
  left: 0;
`

const Logo = styled(AniLink)`
  ${tw`text-xl font-bold`}
  color: ${props => props.theme.menu};
  transition: color 0.2s ease;

  :hover {
    color: ${props => props.theme.menuHover};
  }
`

const MenuWrapper = styled.nav`
  ${tw`flex flex-column items-center justify-end font-bold`};
`

const MenuIcon = styled.button`
  ${tw`fixed md:hidden z-50`};
  position: fixed;
  top: 1.5rem;
  right: 2rem;
  width: 2rem;
  height: 1.5rem;
  padding: 0;
  border: none;
  background-color: transparent;
  transition: all 0.2s ease;
  outline: none;
  cursor: pointer;

  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 0.2rem;
    left: 0;
    background-color: ${colors['grey-dark']};
    transition: all 0.4s ease;
  }

  &::before {
    top: 0;
  }

  &::after {
    bottom: 0;
  }

  .is-open & {
    &::before {
      transform: translateY(0.7rem) rotate(45deg);
    }
    &::after {
      bottom: -1px;
      transform: translateY(-0.7rem) rotate(-45deg);
    }
  }
`

const MenuItems = styled.div`
  ${tw`flex flex-col md:flex-row items-start`};
  ${tw`fixed md:relative pin`};
  ${tw`opacity-0 md:opacity-100`};
  ${tw`py-24 md:py-0`};
  background-color: transparent;

  .is-open & {
    ${tw`z-10 opacity-100`};
    transform: translateX(0);
    transition: opacity 0.25s ease;
  }

  @media (max-width: ${screens.md}) {
    transform: translateX(-100vw);
    background-color: ${colors.white};
    transition: opacity 0.25s ease, background-color 0s linear 0.25s, transform 0s linear 0.25s;
  }
`

const menuItemCss = css`
  ${tw`relative mx-6 py-6 md:py-0 text-4xl md:text-lg text-black`};
  transition: color 0.2s ease 3s;

  @media (min-width: ${screens.md}) {
    color: ${props => props.theme.menu};
    transition: color 0.2s ease;

    :hover {
      color: ${props => props.theme.menuHover};
    }
  }

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 125%;
    width: 0;
    height: 0;
    transform: translate(0, -50%) rotate(-90deg);
    border-left: 18px solid transparent;
    border-right: 18px solid transparent;
    border-bottom: 17px solid ${props => props.theme.menuHover};
    opacity: 0;
    transition: opacity 0s linear 5s;

    @media (min-width: ${screens.md}) {
      top: 125%;
      left: 50%;
      transform: translate(-50%, 25%);
      border-left: 12px solid transparent;
      border-right: 12px solid transparent;
      border-bottom: 11px solid ${props => props.theme.menuHover};
    }
  }

  &.is-active {
    color: ${props => props.theme.menuHover};

    &::after {
      opacity: 1;
    }
  }
`

const MenuItem = styled.a`
  ${menuItemCss}
`

const MenuItemLink = styled(AniLink)`
  ${menuItemCss}
`

const menuItems = [
  { title: 'Work', path: '/' },
  { title: 'About', path: '/about' },
  { title: 'Blog', href: 'https://medium.com/@alexbmorris' },
  { title: 'Resume', href: '/alex-morris-resume.pdf' },
]

const Menu = ({ hideLogo }) => {
  const [isOpen, setIsOpen] = useState(false)
  const linkProps = {
    cover: true,
    direction: 'down',
    duration: 2,
    bg: colors['am-green'],
  }

  return (
    <MenuContent>
      <Inner style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Logo to="/" {...linkProps}>
          {hideLogo ? '' : 'alexbmorris'}
        </Logo>
        <MenuWrapper className={isOpen && 'is-open'}>
          <MenuIcon aria-expanded={isOpen} onClick={() => setIsOpen(!isOpen)} />
          <MenuItems>
            {menuItems.map(item =>
              item.href ? (
                <MenuItem key={item.title} href={item.href} target="_blank">
                  {item.title}
                </MenuItem>
              ) : (
                <MenuItemLink {...linkProps} key={item.title} to={item.path} activeClassName="is-active">
                  {item.title}
                </MenuItemLink>
              )
            )}
          </MenuItems>
        </MenuWrapper>
      </Inner>
    </MenuContent>
  )
}

Menu.propTypes = {
  hideLogo: PropTypes.bool,
}

Menu.defaultProps = {
  hideLogo: false,
}

export default Menu
