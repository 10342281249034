import React from 'react'
import tw from 'tailwind.macro'
import styled from 'styled-components'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconLink } from '../elements/Links'
import { colors } from '../../tailwind'

const FooterContainer = styled.footer`
  ${tw`w-full text-grey-light px-6 pt-8 md:pt-32 pb-8 md:pb-16 text-md lg:text-lg`};
  background-color: #333236;
`

const FooterWrapper = styled.footer`
  ${tw`flex m-auto `};
  max-width: 1200px;
`

const FooterLeft = styled.div`
  ${tw`flex-none`};
`

const FooterRight = styled.div`
  ${tw`hidden md:block flex-auto text-right`};
`

const FooterLink = styled(AniLink)`
  ${tw`text-white font-bold hover:text-green`};
`

const FooterLinkOut = styled.a`
  ${tw`text-white font-bold hover:text-green`};
`

const Footer = () => (
  <FooterContainer>
    <FooterWrapper>
      <FooterLeft>
        <IconLink href="https://vimeo.com/user38578022" target="_blank">
          <FontAwesomeIcon icon={['fab', 'vimeo-v']} />
        </IconLink>
        <IconLink href="https://www.linkedin.com/in/alexbmorris" target="_blank">
          <FontAwesomeIcon icon={['fab', 'linkedin-in']} />
        </IconLink>
        <IconLink href="https://medium.com/@alexbmorris" target="_blank">
          <FontAwesomeIcon icon={['fab', 'medium-m']} />
        </IconLink>
      </FooterLeft>
      <FooterRight>
        Created by{' '}
        <FooterLink to="/about" cover direction="up" duration={2} bg={colors['am-green']}>
          Alex Morris
        </FooterLink>{' '}
        with the help of my dear friend{' '}
        <FooterLinkOut href="https://samrose.me" target="_blank" rel="noopener noreferrer">
          Sam Rose
        </FooterLinkOut>
        .
      </FooterRight>
    </FooterWrapper>
  </FooterContainer>
)

export default Footer
