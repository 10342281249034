import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import { colorLuminance } from '../utils/colorLuminance'
import { colors } from '../../tailwind'

const AniArrowLinkWrapper = styled(AniLink)`
  display: inline-flex;
  align-items: center;
  transition: opacity 0.15s ease-in-out;
  color: ${props => props.color};
  transition: color 0.15s ease-in-out;

  :hover {
    color: ${props => colorLuminance(props.color, -0.2)};

    .arrow-stem {
      transform: scaleX(2);
    }
    .arrow-end {
      transform: translateX(20px);
    }
  }

  .arrow {
    margin-left: 0.5rem;
    .arrow-stem,
    .arrow-end {
      transition: transform 0.15s ease-in-out;
    }
  }
`

const ArrowLinkText = styled.span`
  ${tw`relative inline-block text-xl font-bold`};
  color: ${props => props.color};
`

const ArrowIcon = () => (
  <svg className="arrow" width="44" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 20">
    <g strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" stroke="currentColor">
      <line className="arrow-stem" x1="2" y1="9.5" x2="17" y2="9.5" />
      <polyline className="arrow-end" points="12,3.5 18,9.5 12,16" />
    </g>
  </svg>
)

export const ArrowLink = ({ bg, color, children, ...props }) => (
  <AniArrowLinkWrapper duration={2} bg={bg || color} color={color} {...props}>
    <ArrowLinkText>{children}</ArrowLinkText>
    <ArrowIcon />
  </AniArrowLinkWrapper>
)

ArrowLink.propTypes = {
  bg: PropTypes.string,
  color: PropTypes.string,
  children: PropTypes.node.isRequired,
}

ArrowLink.defaultProps = {
  bg: '',
  color: 'currentColor',
}

export const IconLink = styled.a`
  ${tw`mr-1 p-2 text-white hover:text-green cursor-pointer`};
  transition: color 0.15s ease-in-out;
`

const StyledLink = styled(AniLink)`
  ${tw`relative font-bold`};
  color: currentColor;
  background-image: linear-gradient(120deg, ${colors['am-blue']}, ${colors['am-blue']});
  background-repeat: no-repeat;
  background-size: 100% 20%;
  background-position: 0 100%;
  transition: background-size 0.15s ease;

  :hover {
    background-size: 100% 50%;
  }
`

export const StyledLinkOut = StyledLink.withComponent('a')
export const StyledLinkIn = StyledLink
StyledLinkIn.defaultProps = {
  cover: true,
  duration: 2,
}
