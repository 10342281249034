import React from 'react'
import PropTypes from 'prop-types'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import GlobalStyle from '../styles/global'
import SEO from './SEO'

import 'typeface-raleway'
import 'typeface-open-sans'

library.add(fas, fab)

const Layout = ({ children }) => {
  const [render, setRender] = React.useState(false)
  React.useEffect(() => setRender(true), [])
  return (
    <>
      <SEO />
      {render && (
        <>
          <GlobalStyle />
          {children}
        </>
      )}
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
