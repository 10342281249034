const tailwind = require('../tailwind')

module.exports = {
  pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "/portfolio"

  siteTitle: 'Alex Morris - UX Designer', // Navigation and Site Title
  siteTitleAlt: 'Alex Morris', // Alternative Site title for SEO
  siteTitleShort: 'Alex', // short_name for manifest
  siteHeadline: 'UX Designer making life better through design', // Headline for schema.org JSONLD
  siteUrl: 'https://alexbmorris.com', // Domain of your site. No trailing slash!
  siteLanguage: 'en', // Language Tag on <html> element
  siteLogo: '/social.jpg', // Used for SEO and manifest
  siteDescription: 'I’m a user experience designer that enjoys making life better through design.',
  author: 'Alex Morris', // Author for schema.org JSONLD

  // siteFBAppID: '123456789', // Facebook App ID - Optional
  // userTwitter: '@alexmorris', // Twitter Username
  // ogSiteName: 'alexmorris', // Facebook Site Name
  // ogLanguage: 'en_US', // Facebook Language
  // googleAnalyticsID: 'UA-XXXXXXXX-X',

  // Manifest and Progress color
  themeColor: tailwind.colors.green,
  backgroundColor: tailwind.colors.white,
}
